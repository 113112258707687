<template>
  <div class="login-container">
    <h1>Login</h1>
    <v-form :disabled="loading" ref="loginForm" @submit.prevent="handleLogin()">
      <v-container>
        <v-text-field
          type="email"
          prepend-icon="mdi-account-circle"
          v-model="form.username"
          :rules="requiredRules"
          dark
          placeholder="Email"
          style
        ></v-text-field>
        <v-text-field
          :type="showPassword ? 'text' : 'password'"
          prepend-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          v-model="form.password"
          :rules="requiredRules"
          dark
          placeholder="Senha"
        ></v-text-field>
        <v-btn type="submit" :loading="loading" color="success">Entrar</v-btn>
      </v-container>
    </v-form>
  </div>
</template>

<script>
//import axios from "axios";
import http from "@/modules/http";
export default {
  data: () => {
    return {
      showPassword: false,
      loading: false,
      form: {
        username: "",
        password: "",
      },
      usernameRule: [
        (v) => !!v || "Preencha seu e-mail",
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Insira um e-mail válido",
      ],
      passwordRule: [(v) => !!v || "Digite sua senha"],
      requiredRules: [(v) => !!v || "Preencha este campo"],
    };
  },
  methods: {
    handleLogin() {
      if (this.$refs.loginForm.validate()) {
        this.loading = true;
        http
          .post("oauth/token", {
            grant_type: process.env.VUE_APP_GRANT_TYPE,
            client_id: process.env.VUE_APP_CLIENT_ID,
            client_secret: process.env.VUE_APP_CLIENT_SECRET,
            username: this.form.username,
            password: this.form.password,
            scope: "",
          })
          .then((res) => {
            this.$store.dispatch("set_token", res.data.access_token);
            http
              .get("api", {
                headers: {
                  Accept: "application/json",
                  Authorization: `Bearer ${res.data.access_token}`,
                },
              })
              .then((user) => {
                this.$store.dispatch("set_user", user.data);
              })
              .catch((e) => {
                console.log(e);
              });
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.$side({
              type: "error",
              msg: "Erro ao fazer login",
              duration: 3000,
            });
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.login-container {
  max-width: 600px;
  width: 100%;
  height: 400px;
  background: rgb(12, 23, 27);
  margin: 0 auto;
  box-shadow: 0 0 10px;
  border-radius: 20px;
}

h1 {
  margin-left: 40px;
  font-size: 48px;
  padding-top: 20px;
  color: lightcyan;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: yellow !important;
}
</style>